<template>
  <header class="header">
    <div class="header-content">
      <router-link to="/" class="logo">
        <picture>
          <source srcset="/images/logo_mobile.svg" media="(max-width: 920px)" />
          <source srcset="/images/logo.svg" />
          <img src="/images/logo.svg" alt="logo" />
        </picture>
      </router-link>

      <div class="spacer"></div>
      <div class="header-count">
        <span class="basegreen-text">6026</span> Single Girls
      </div>
      <div class="header-count">
        <span class="green-text">{{ userOnlineCount }}</span> Online Now
      </div>
      <AppButton class="btn-yellow btn-large" @click="openModal"
        >Create Account
        <span class="green-label free-label">Free</span></AppButton
      >
    </div>
  </header>
</template>

<script>
import AppButton from "@/components/AppButton";
import openSignUpModalMixin from "@/mixins/openSignUpModalMixin";
export default {
  name: "AppHeader",
  components: { AppButton },
  computed: {
    userOnlineCount() {
      return Math.floor(Math.random() * (421 - 321 + 1)) + 321;
    }
  },
  mixins: [openSignUpModalMixin]
};
</script>
