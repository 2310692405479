<template>
  <div class="block-checkbox" @click="onClick" :style="bgColor">
    <i class="vc vc-check" v-if="active"></i>
  </div>
</template>

<script>
export default {
  name: "AppFakeCheckBoxColor",
  props: {
    color: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      active: true,
      timeout: null
    };
  },
  computed: {
    bgColor() {
      return this.color
        ? {
            background: this.color
          }
        : {};
    }
  },
  methods: {
    onClick() {
      clearTimeout(this.timeout);
      this.active = false;
      this.timeout = setTimeout(() => {
        this.active = true;
      }, 300);
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped lang="scss">
.block-checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
}
</style>
