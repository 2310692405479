<template>
  <div class="custom-checkbox-wrap">
    <div class="custom-checkbox" @click="onClick">
      <div class="active-indicator" v-if="active"></div>
    </div>
    <span @click="onClick">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AppFakeCheckBox",
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      active: true,
      timeout: null
    };
  },
  methods: {
    onClick() {
      clearTimeout(this.timeout);
      this.active = false;
      this.timeout = setTimeout(() => {
        this.active = true;
      }, 300);
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped lang="scss">
.custom-checkbox-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #343740;
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 3px;
  border: 1px solid #a4a4a4;
  padding: 3px;
  cursor: pointer;
  margin-right: 10px;
}
.active-indicator {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: #52cba7;
}
</style>
