<template>
  <button class="btn"><slot></slot></button>
</template>

<script>
export default {
  name: "AppButton"
};
</script>

<style scoped></style>
