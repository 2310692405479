<template>
  <router-link
    :to="{ name: 'Profile', params: { id: user.id } }"
    class="profile-card"
  >
    <div class="hover">
      <div class="buttons special-actions-buttons">
        <button
          class="striped-button full-width"
          @click.prevent.stop="openModal"
        >
          <i class="vc vc-chat"></i>
          <span>Chat now</span>
        </button>
        <div>
          <button class="striped-button" @click.prevent.stop="openModal">
            <i class="vc vc-star"></i>
          </button>
          <button class="striped-button" @click.prevent.stop="openModal">
            <i class="vc vc-mail"></i>
          </button>
          <button class="striped-button" @click.prevent.stop="openModal">
            <i class="vc vc-eye"></i>
          </button>
          <button class="striped-button" @click.prevent.stop="openModal">
            <i class="vc vc-like"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="static">
      <div class="photo special-photo">
        <span class="ico-online blink"></span>
        <span
          data-tooltip="She has a webcam for video chat"
          class="ico-cam"
        ></span>
        <img
          :src="user.photo ? `//storage.victoriyaclub.com${user.photo}` : ''"
          :alt="user.name"
          class="lazyload"
        />
        <div data-note="true" class="sticker-user-btn right clear">
          <div
            data-tooltip-content="#sticker_girl_list_119530"
            class="btn-tooltip tooltipster"
          ></div>
          <div class="note-wrapper"></div>
        </div>
      </div>
      <div class="container-for-cart">
        <div class="name">{{ user.name }}</div>
        <div class="age">Age: {{ user.age }}</div>
        <div class="id">ID: {{ user.id }}</div>
      </div>
      <div class="targets special-targets">
        I need:
        <div class="d-flex">
          <i class="vc vc-plane"></i>
          <i class="vc vc-wedding"></i>
          <i class="vc vc-comment"></i>
          <i class="vc vc-users"></i>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import openSignUpModalMixin from "@/mixins/openSignUpModalMixin";

export default {
  name: "AppProfileCard",
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [openSignUpModalMixin]
};
</script>
