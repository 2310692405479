<template>
  <AppHeader />
  <div class="main">
    <router-view :key="$route.params.id" />
  </div>
  <AppFooter />
  <AppSignUpModal v-model="modelDialog" />
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import AppSignUpModal from "@/components/AppSignUpModal";
import { mapActions } from "vuex";
import $ from "jquery";
export default {
  components: { AppSignUpModal, AppFooter, AppHeader },
  data() {
    return {
      modelDialog: false
    };
  },
  methods: {
    ...mapActions({
      fetchUsers: "fetchUsers",
      getIp: "getIp"
    }),
    async sendPidAndSid() {
      if (process.env.NODE_ENV === "development") return;
      let params = new URL(document.location).searchParams;
      let pid = params.get("pid"),
        sid = params.get("sid"),
        referer = document.referer;
      $.ajax({
        url: "/api/?action=send_partner_info",
        method: "post",
        data: "pid=" + pid + "&sid=" + sid + "&referer=" + referer,
        crossDomain: true,
        success: function() {
          console.log("Success");
        }
      });
    }
  },
  mounted() {
    this.getIp();
    this.sendPidAndSid();
  },
  created() {
    this.emitter.on("openModal", isOpen => {
      this.modelDialog = isOpen;
    });
  }
};
</script>
