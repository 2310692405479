<template>
  <transition name="fade">
    <div class="popup" @click.self="closeModal" v-if="modelValue">
      <div class="popup-container">
        <button @click="closeModal" class="btn-close">
          <i class="vc vc-close"></i>
        </button>
        <div class="sign-in-wrap">
          <div class="sign-up--title">Sign Up <span>FREE</span></div>
          <form action="" @submit.prevent="submit">
            <div class="flex-grow">
              <AppInput
                name="name"
                placeholder="John"
                label="First name:"
                required
                prepend-icon="vc-user"
                v-model="form.name"
              />
              <AppInput
                name="email"
                placeholder="example@email.com"
                label="E-mail:"
                required
                prepend-icon="vc-email"
                type="email"
                v-model="form.email"
              />
              <AppInput
                name="pass"
                placeholder="******"
                autocomplete="on"
                label="Your password:"
                prepend-icon="vc-lock"
                required
                :type="passwordType"
                v-model="form.password"
              />
              <div class="form-check">
                <label
                  ><input
                    type="checkbox"
                    required="required"
                    name="sign_agree"
                    checked="checked"
                    class="checkbox"
                  />
                  <span>I agree with License agreement</span></label
                >
              </div>
            </div>
            <div class="form-buttons">
              <AppButton class="btn-green">Create an account</AppButton>
            </div>
          </form>
        </div>
        <div class="copyright">
          2008-2021 © All rights reserved
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppInput from "@/components/AppInput";
import { mapState } from "vuex";
import $ from "jquery";
export default {
  name: "AppSignUpModal",
  props: {
    modelValue: Boolean
  },
  emits: ["update:modelValue"],
  data() {
    return {
      passwordType: "password",
      form: {
        name: "",
        email: "",
        password: "",
        checked: false
      }
    };
  },
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.setSignUpModal();
      } else {
        this.closeSignUpModal();
      }
    }
  },
  components: { AppInput, AppButton },
  computed: {
    ...mapState({
      ip: state => state.ip
    })
  },
  methods: {
    closeModal() {
      this.$emit("update:modelValue", false); // previously was `this.$emit('input', title)`
    },
    setSignUpModal() {
      document
        .querySelector("body")
        .classList.add("sign-popup", "overflow-hidden");
    },
    closeSignUpModal() {
      document
        .querySelector("body")
        .classList.remove("sign-popup", "overflow-hidden");
    },
    submit(evt) {
      evt.preventDefault();
      // gtag_report_conversion();
      let params = new URL(document.location).searchParams;
      let data = $(evt.target).serialize();
      let ip = this.ip;
      data +=
        "&pass2=" +
        $(evt.target.pass).val() +
        "&ip=" +
        ip +
        "&pid=" +
        params.get("pid") +
        "&pid_sid=" +
        params.get("sid");
      $.post(
        "/api/?action=registration",
        data,
        function(resp) {
          console.log("Success");
          if (resp.location)
          {
            setTimeout(
              function() {
                document.location = resp.location;
              },
              500);
          }

        }
      );
    }
  },
  mounted() {
    if (this.modelValue) {
      this.setSignUpModal();
    } else {
      this.closeSignUpModal();
    }
  }
};
</script>

<style scoped lang="scss">
.btn-close {
  display: none;
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
    background: rgba(#f0f0f0, 0.6);
    color: #6e6c79;
    display: flex;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  overflow: auto;
  z-index: 999;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.popup-container {
  width: 100%;
  max-width: 600px;
  height: 700px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: auto;
  @media (max-width: 768px) {
    height: 100%;
    overflow: auto;
    border-radius: 0;
    padding: 15px;
  }
  .copyright {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.42);
    margin-top: 20px;
  }
}

.sign-in-wrap {
  width: 100%;
  max-width: 270px;
  margin: auto;
  .form-control {
    margin-bottom: 20px;
  }
}

.form-check {
  margin-bottom: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
