import { createStore } from "vuex";
import _ from "lodash";

export default createStore({
  state: {
    allUsers: [],
    ip: ""
  },
  mutations: {
    SET_ALL_USERS(state, users) {
      state.allUsers = users;
    },
    SET_USER_IP(state, ip) {
      state.ip = ip;
    }
  },
  actions: {
    getIp({ commit }) {
      fetch("https://api.ipify.org?format=json")
        .then(x => x.json())
        .then(({ ip }) => {
          commit("SET_USER_IP", ip);
        });
    },
    async fetchUsers({ state, commit }) {
      if (state.allUsers.length > 0) return state.allUsers;
      const url =
        process.env.NODE_ENV === "development"
          ? "/api/files/acounts.json"
          : "/api/?action=get_profiles";
      const options =
        process.env.NODE_ENV === "development"
          ? {}
          : {
              method: "POST"
            };
      const response = await fetch(url, options);
      const users = await response.json();
      const usersArray = Object.values(users).map(item => {
        if (item.photos) {
          if (typeof item.photos === 'string'){
            item.photos = JSON.parse(item.photos);
          }
        } else {
          item.photos = [];
        }
        return item;
      });

      commit("SET_ALL_USERS", usersArray);
      return usersArray;
    }
  },
  getters: {
    usersRandom(state) {
      return _.shuffle(state.allUsers)
        .slice(0, 20)
        .map(user => {
          return {
            ...user
          };
        });
    },
    allUsers(state) {
      return state.allUsers;
    }
  },
  modules: {}
});
