<template>
  <footer class="footer">
    2008-2021 © All rights reserved
  </footer>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped lang="scss">
.footer {
  height: 59px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(#000, 0.42);
  margin-top: auto;
}
</style>
